
import { Component, Ref, Watch } from "vue-property-decorator";
import Table from "@/components/Table.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import Header from "@/components/Header.vue";
import FacilityService from "@/services/facilities";
import CheckAdminLogin from "@/utils/CheckAdminLogin";
import FacilityModule from "@/store/facility";
import Papa from "papaparse";
import Encoding from "encoding-japanese";
import FormSet from "@/components/FormSet.vue";
import { HospitalData } from "@/@types/management";
import Button from "@/components/Button.vue";

@Component({
  name: "FacilityManagement",
  components: {
    Table,
    Header,
    ManagementFooter,
    FormSet,
    Button,
  },
})
export default class FacilityManagementDetail extends CheckAdminLogin {
  @Ref() private tableComponent!: Table;
  private hospitalList: HospitalData[] = [];

  private selectedHospital: any = null;

  private copySuccess = false;

  private hospitalDeleteError = false;

  private errorMessage = "";

  private async mounted(): Promise<void> {
    const facility_id = this.$route.params.facility_id;
    const res = await FacilityService.get(facility_id);
    this.selectedHospital = res.data.data[0];
    if (this.selectedHospital) {
      FacilityModule.changeHospitalId(this.selectedHospital.id);
    } else {
      this.$router.push({ name: "FacilityManagement" });
    }
  }

  @Watch("selectedHospital", { deep: true })
  private getFacilityStaffs() {
    FacilityModule.changeHospitalId(this.selectedHospital.id);
  }

  private loading = false;

  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private deleteCancel() {
    this.closeModal("facilityDelete");
    this.hospitalDeleteError = false;
    clearInterval(this.timeoutId);
  }

  // private changeHospital(value: any) {
  //   console.log(value);
  //   this.selectedHospital = value;
  // }

  private facilityLink = `${process.env.VUE_APP_BASE_URL}/course-select?id=`;

  private timeoutId: any = undefined;

  private async deleteHospital() {
    try {
      this.loading = true;
      await FacilityService.delete(this.selectedHospital.id);
      this.loading = false;
      this.$router.push({ name: "FacilityManagement" });
    } catch (error: any) {
      this.loading = false;
      this.errorMessage = error.response.data.error_msg;
      if (this.errorMessage.includes("information_id")) {
        this.errorMessage = "お知らせが設定されているため、削除できません";
      } else if (this.errorMessage.includes("course_id")) {
        this.errorMessage = "コースが設定されているため、削除できません";
      } else if (this.errorMessage.includes("reservation_id")) {
        this.errorMessage = "未来の予約が存在するため、削除できません";
      } else if (this.errorMessage.includes("customer_id")) {
        this.errorMessage = "顧客情報が存在するため、削除できません";
      } else if (this.errorMessage.includes("patient_id")) {
        this.errorMessage = "患者情報が存在するため、削除できません";
      } else if (this.errorMessage.includes("operator_id")) {
        this.errorMessage = "病院スタッフが存在するため、削除できません";
      } else if (this.errorMessage.includes("facility_id")) {
        this.errorMessage = "この施設は存在しません";
      }
      this.hospitalDeleteError = true;
      this.timeoutId = setTimeout(() => {
        this.hospitalDeleteError = false;
      }, 10000);
      throw new Error(error);
    }
  }

  private hospitalEdit(id: string) {
    this.$router.push({ name: "FacilityManagementHospitalEdit", params: { id } });
    window.scrollTo(0, 0);
  }

  private toStaffEdit() {
    if (this.selectedHospital) {
      this.$router.push({ name: "FacilityManagementStaffEdit", params: { facility_id: this.selectedHospital.id } });
    }
  }
  private linkCopy(text: string) {
    this.$copyText(text);
    this.copySuccess = true;
    setTimeout(() => {
      this.copySuccess = false;
    }, 3000);
  }
  private getFacilityCsvData() {
    const config = {
      delimiter: ",", // 区切り文字
      header: true, // キーをヘッダーとして扱う
      newline: "\r\n", // 改行
    };
    const jsonDataList: any = [];
    if (this.selectedHospital) {
      const jsonData = {
        病院ID: this.selectedHospital.id,
        病院名: this.selectedHospital.name,
        住所: this.selectedHospital.address,
        電話番号: this.selectedHospital.tel,
        Webサイト: this.selectedHospital.url,
        通知用メールアドレス: this.selectedHospital.email,
        診療動物の種類: this.selectedHospital.animal_types,
        獣医師スケジュール: this.selectedHospital.doctor_schedule,
        アクセス方法: this.selectedHospital.access,
        契約コード: this.selectedHospital.contract_id,
      };
      jsonDataList.push(jsonData);
    } else {
      this.hospitalList.forEach((hospital: HospitalData) => {
        const jsonData = {
          病院ID: hospital.id,
          病院名: hospital.name,
          住所: hospital.address,
          電話番号: hospital.tel,
          Webサイト: hospital.url,
          通知用メールアドレス: hospital.email,
          診療動物の種類: hospital.animal_types,
          獣医師スケジュール: hospital.doctor_schedule,
          アクセス方法: hospital.access,
          契約コード: hospital.contract_id,
        };
        jsonDataList.push(jsonData);
      });
    }
    // CSVデータ取得
    // 区切り文字へ変換
    const delimiterString = Papa.unparse(jsonDataList, config);

    // blodへの変換
    const strArray = Encoding.stringToCode(delimiterString);
    const convertedArray = Encoding.convert(strArray, "SJIS", "UNICODE");
    const UintArray = new Uint8Array(convertedArray);
    const blob = new Blob([UintArray], { type: "text/csv" });

    // download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `施設情報.csv`;
    link.click();
  }
}
