
import { Component, Vue, Watch } from "vue-property-decorator";
import AuthModule from "@/store/auth";
import Cognito from "@/plugins/cognito";
import Button from "@/components/Button.vue";

@Component({
  components: {
    Button,
  },
})
export default class Header extends Vue {
  private get me() {
    return AuthModule.me;
  }
  private routeName = this.$route.name;

  private get checkFacilityRoute() {
    if (
      this.routeName === "FacilityManagement" ||
      this.routeName === "FacilityManagementDetail" ||
      this.routeName === "FacilityManagementStaffEdit" ||
      this.routeName === "FacilityManagementHospitalCreate" ||
      this.routeName === "FacilityManagementHospitalEdit"
    ) {
      return true;
    }
    return false;
  }
  private get checkStaffRoute() {
    if (this.routeName === "OperatorsManagement" || this.routeName === "OperatorEdit") {
      return true;
    }
    return false;
  }
  private get checkNoticeRoute() {
    if (this.routeName === "HospitalAnnouncementSetting" || this.routeName === "HospitalAnnouncementSettingEdit") {
      return true;
    }
    return false;
  }

  private signOut() {
    Cognito.signOut();
    this.$router.push({ name: "Login" });
  }

  private toHospitalSelect() {
    this.$router.push({ name: "HospitalSelect" });
  }

  private toChangePassword() {
    const routeName = this.$route.name;
    AuthModule.changePassRoot(routeName ?? "");
    this.$router.push({ name: "ChangePassword" });
  }

  @Watch("$route", { immediate: true, deep: true })
  private changeRoute() {
    if (this.$route.name) this.routeName = this.$route.name;
  }
}
