
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FacilityService from "@/services/facilities";
import MemberService from "@/services/members";
import FacilityModule from "@/store/facility";
import { CreateOperator, OperatorData } from "@/@types/management";
import { deleteNullProp } from "@/utils/Util";
import Encoding from "encoding-japanese";
import Papa from "papaparse";
import Button from "@/components/Button.vue";
import FormSet from "@/components/FormSet.vue";

@Component({
  components: {
    Button,
    FormSet,
  },
})
export default class Table extends Vue {
  private get hospitalId() {
    return FacilityModule.hospitalId;
  }
  private search = "";

  private routeName = this.$route.name;

  private saveSuccess = false;

  private loading = false;

  private tableLoading = false;
  private deleteLoading = false;

  private operatorCreateError = false;
  private errorMessage = "";

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private csvData: any = [];

  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private openDetailInfo(info: any) {
    this.selectedInfo = info;
    if (this.routeName === "FacilityManagementStaffEdit" || this.routeName === "AdminManagement") {
      this.showModal("delete");
    } else {
      this.showModal("detail-info");
    }
  }

  private deleteModalOpen(info: any) {
    this.selectedInfo = info;
    this.showModal("delete");
  }

  private deleteNext() {
    this.closeModal("delete");
    this.showModal("delete-confirm");
  }

  private async deleteMember() {
    if (this.selectedInfo) {
      this.deleteLoading = true;
      await MemberService.updateMember(this.selectedInfo.id, { member_status: "non_member" }, "true");
      await MemberService.delete(this.selectedInfo.id);
      await this.getUsers();
      this.deleteLoading = false;
    }
    this.closeModal("delete-confirm");
  }

  private openBlock(info: any) {
    this.selectedInfo = info;
    this.showModal("block");
  }

  private ellipsisText(text: string, textDispMaxLength: number) {
    if (text.length > textDispMaxLength) {
      return text.slice(0, textDispMaxLength) + "...";
    }
    // 文章省略がない場合はもっと見るを開いた状態にする
    return text;
  }

  private selectedInfo: any = null;

  private createOperatorForm: CreateOperator = {
    first_name: "",
    first_name_kana: "",
    last_name: "",
    last_name_kana: "",
    role: "operator",
    email: "",
    password: "",
    facility_ids: [],
  };

  private resetOperationForm() {
    this.createOperatorForm = {
      first_name: "",
      first_name_kana: "",
      last_name: "",
      last_name_kana: "",
      role: "operator",
      email: "",
      password: "",
      facility_ids: [],
    };
  }

  private headers = [
    { text: "会員ID", width: "30%", value: "id", align: "center" },
    { text: "メールアドレス", width: "48%", value: "email", align: "center" },
    { text: "会員ステータス", width: "12%", value: "actions", sortable: false, align: "center" },
    { text: "", value: "delete", width: "10%", sortable: false, align: "center" },
  ];

  private operatorsHeaders = [
    { text: "会員ID", value: "id" },
    { text: "名前", value: "name" },
    { text: "メールアドレス", value: "email" },
    { text: "", value: "actions", sortable: false },
  ];

  private facilitiesHeaders = [
    { text: "病院名", value: "name" },
    { text: "住所", value: "address" },
    { text: "電話番号", value: "tel" },
    { text: "契約コード", value: "contract_id" },
    { text: "", value: "actions", sortable: false },
  ];

  private facilityOperatorsHeaders = [
    { text: "アカウントID", value: "id" },
    { text: "名前", value: "name" },
    { text: "メールアドレス", value: "email" },
    { text: "", value: "actions", sortable: false },
  ];

  private noticeSettingHeaders = [
    { text: "お知らせタイトル", value: "title" },
    { text: "公開開始日", value: "startAt" },
    { text: "公開終了日", value: "endAt" },
    { text: "最終更新日時", value: "updatedAt" },
  ];
  private hospitalUsersHeaders = [
    { text: "飼主姓名", value: "owner_name", sortable: false },
    { text: "電話番号", value: "customer.tel", sortable: false },
    { text: "診察券番号", value: "patient_alias_id", sortable: false },
    { text: "どうぶつ名", value: "patient_name", sortable: false },
    { text: "どうぶつ種", value: "patient_animal_type", sortable: false },
    { text: "品種", value: "patient_animal_breed", sortable: false },
    { text: "性別", value: "patient_animal_gender", width: 130, sortable: false },
    { text: "", value: "actions", sortable: false },
  ];

  private async getCustomersPatients() {
    const res = await FacilityService.getCustomersPatients(this.hospitalId);
    this.hospitalUsers = res.data.data;
  }

  private async getFacilities() {
    const res = await FacilityService.getAll();
    this.users = res.data.data;
  }

  private getCustomersPatientsCsvData() {
    const config = {
      delimiter: ",", // 区切り文字
      header: true, // キーをヘッダーとして扱う
      newline: "\r\n", // 改行
    };
    const jsonDataList: any = [];
    this.hospitalUsers.forEach((user: any) => {
      const name =
        user.customer_last_name && user.customer_first_name
          ? user.customer_last_name + "　" + user.customer_first_name
          : "(" + user.customer.last_name_kana + "　" + user.customer.first_name_kana + ")";
      const jsonData = {
        飼主姓名: name,
        電話番号: user.customer.tel,
        診察券番号: user.patient_alias_id,
        どうぶつ名: user.patient_name ? user.patient_name : `(${user.patient_name_kana})`,
        どうぶつ種: user.patient_animal_type,
        品種: user.patient_animal_breed,
        性別: user.patient_animal_gender,
      };
      // CSVデータ取得
      jsonDataList.push(jsonData);
    });
    // 区切り文字へ変換
    const delimiterString = Papa.unparse(jsonDataList, config);

    // blodへの変換
    const strArray = Encoding.stringToCode(delimiterString);
    const convertedArray = Encoding.convert(strArray, "SJIS", "UNICODE");
    const UintArray = new Uint8Array(convertedArray);
    const blob = new Blob([UintArray], { type: "text/csv" });

    // download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `顧客リスト.csv`;
    link.click();
  }

  private getFacilitiesCsvData() {
    const config = {
      delimiter: ",", // 区切り文字
      header: true, // キーをヘッダーとして扱う
      newline: "\r\n", // 改行
    };
    const jsonDataList: any = [];
    this.users.forEach((user: any) => {
      const jsonData = {
        病院名: user.name,
        住所: user.address ? user.address : "",
        電話番号: user.tel ? user.tel : "",
        契約コード: user.contract_id ? user.contract_id : "",
      };
      // CSVデータ取得
      jsonDataList.push(jsonData);
    });
    // 区切り文字へ変換
    const delimiterString = Papa.unparse(jsonDataList, config);

    // blodへの変換
    const strArray = Encoding.stringToCode(delimiterString);
    const convertedArray = Encoding.convert(strArray, "SJIS", "UNICODE");
    const UintArray = new Uint8Array(convertedArray);
    const blob = new Blob([UintArray], { type: "text/csv" });

    // download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `施設一覧.csv`;
    link.click();
  }

  private async getUsers() {
    const res = await MemberService.getAll();
    this.users = res.data.data;
  }
  private async getOperators() {
    const res = await FacilityService.getOperators();
    this.users = res.data.data;
  }

  @Watch("hospitalId")
  private async getOperatorsByFacilityId() {
    const res = await FacilityService.getOperatorsByFacilityId(this.hospitalId);
    this.users = res.data.data;
  }

  private toFacilityDetail(id: string) {
    this.$router.push({ name: "FacilityManagementDetail", params: { facility_id: id } });
  }

  private blockLoading = false;
  private async changeEnabled() {
    try {
      this.blockLoading = true;
      await MemberService.updateMember(
        this.selectedInfo.id,
        {
          member_status: this.selectedInfo.member_status === "member" ? "non_member" : "member",
        },
        "true"
      );
      this.getUsers();
      this.blockLoading = false;
      this.closeModal("block");
    } catch (error: any) {
      this.blockLoading = false;
      throw new Error(error);
    }
  }

  private editUser(id: string) {
    this.$router.push({ name: "OperatorEdit", params: { id } });
  }

  private hospitalUsersCustomFilter(value: any, search: string, item: any) {
    return (
      (!!search && item.customer_last_name?.concat(item.customer_first_name).includes(search)) ||
      (item.patient_alias_id && item.patient_alias_id.includes(search)) ||
      (item.patient_name && item.patient_name.includes(search)) ||
      (item.patient_animal_breed && item.patient_animal_breed.includes(search)) ||
      (item.patient_animal_type && item.patient_animal_type.includes(search)) ||
      (item.customer.tel && item.customer.tel.includes(search)) ||
      (item.patient_name_kana && item.patient_name_kana.includes(search)) ||
      (item.customer.first_name_kana && item.customer.first_name_kana.includes(search)) ||
      (item.customer.last_name_kana && item.customer.last_name_kana.includes(search))
    );
  }
  private usersCustomFilter(value: any, search: string, item: any) {
    return (
      (!!search && (item.id.includes(search) || item.email.includes(search))) ||
      (item.member_status === "member" && search === "会員") ||
      (item.member_status === "non_member" && search === "退会")
    );
  }
  private operatorCustomFilter(value: any, search: string, item: any) {
    return (
      (!!search && (item.id.includes(search) || item.email.includes(search))) ||
      item.last_name.includes(search) ||
      item.first_name.includes(search)
    );
  }

  private async deleteAdmin(data: OperatorData) {
    try {
      this.loading = true;
      const operator = data;
      const id = operator.id;
      operator.role = "operator";
      const payload = deleteNullProp(operator);
      await FacilityService.updateOperator(id, payload);
      await this.getOperators();
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      throw new Error(error);
    }
  }

  private async mounted() {
    this.tableLoading = true;
    if (this.$route.name === "UsersManagement") {
      await this.getUsers();
    }
    if (this.$route.name === "OperatorsManagement") {
      await this.getOperators();
    }
    if (this.$route.name === "FacilityManagement") {
      await this.getFacilities();
    }
    if (this.$route.name === "FacilityManagementDetail") {
      await this.getOperatorsByFacilityId();
    }
    this.tableLoading = false;
  }

  private users = [];

  private notices = [];

  private hospitalUsers = [];

  private async submit() {
    try {
      this.loading = true;
      const payload = deleteNullProp(this.createOperatorForm);
      await FacilityService.createOperator(payload);
      this.closeModal("add-account");
      this.saveSuccess = true;
      this.loading = false;
      this.getOperators();
      this.resetOperationForm();
      setTimeout(() => {
        this.saveSuccess = false;
      }, 3000);
    } catch (error: any) {
      this.showModal("saveError");
      this.loading = false;
      // this.operatorCreateError = true;
      this.errorMessage = error.response.data.error_msg;
      setTimeout(() => {
        this.operatorCreateError = false;
      }, 5000);
      throw new Error(error);
    }
  }
}
